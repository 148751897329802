import React, { FC, ReactNode } from 'react';
import { InView } from 'react-intersection-observer';

import useAnalytics from '../../lib/segment/useAnalytics';

export enum ImpressionType {
  TRACK = 'TRACK',
  PAGE = 'PAGE',
}
interface SegmentImpressionProps {
  eventName: string;
  eventProps?: Record<string, any>;
  children: ReactNode;
  className?: string;
  eventType?: ImpressionType;
}

const SegmentImpression: FC<SegmentImpressionProps> = ({
  eventName,
  eventProps = {},
  children,
  className,
  eventType,
}) => {
  const { track } = useAnalytics();
  const handleViewChange = (inView) => {
    if (inView) {
      if (eventType === ImpressionType.PAGE) {
        // There aren't any use cases for Segment Page Impressions yet,
        // but it can be implemented here in the future.
        //
        // Throw an error to assert that `<SegmentImpression>` currently
        // only handles `track()` impressions, not `page()` impressions.
        throw new Error('For future implementation');
      } else {
        track(eventName, eventProps);
      }
    }
  };

  return (
    <InView onChange={handleViewChange} className={className} triggerOnce>
      {children}
    </InView>
  );
};

export default SegmentImpression;
